import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'utils/react-query';
import classnames from 'classnames';

import SectionBlock from 'components/Globals/SectionBlock';
import ClaimProfileCard from 'components/Artist/Display/ClaimProfileCard';
import Skeleton from 'components/uiLibrary/Loaders/Skeleton';
import { TRANSFORMATIONS } from 'components/uiLibrary/Image';
import HorizontalScrollIndicators from 'components/uiLibrary/HorizontalScrollIndicators';

import { TP } from 'constants/index';
import queries from 'containers/Globals/queries';

import { useTranslation } from 'src/i18n';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import usePageContext from 'utils/hooks/usePageContext';
import { ENTITY_TYPES } from 'operabase-router/constants';
import { getEntityRecommendationApiLimit } from 'utils/globals';
import PreviewCard from '../PreviewCard';
import EntityCard from '../EntityCard';

import classes from './EntitySuggestions.module.scss';

const RenderOption = ({
  response,
  inline,
  isGrid,
  entityType,
  trackingData,
  showClaimProfileCard,
  claimProfileTrackingData,
}) => {
  const { isMobile, isTablet } = useDeviceTypeLayouts();
  return (
    <>
      {response?.data?.map((data, index) => {
        if (inline) {
          return (
            <Fragment key={data?.id}>
              <EntityCard
                key={data?.id}
                entity={data}
                entityType={entityType}
                width={30}
                height={30}
                transformations={TRANSFORMATIONS.PROFILE_THUMBNAIL}
                typographyProps={{
                  name: {
                    truncate: true,
                  },
                  subtext: {
                    truncate: true,
                  },
                }}
                className={classnames(classes.inline_card, {
                  [classes.inline_card_grid]: !!isGrid,
                })}
                noFollow
                trackingData={trackingData}
              />
              {showClaimProfileCard && isMobile && index === 0 && (
                <ClaimProfileCard trackingData={claimProfileTrackingData} />
              )}
              {showClaimProfileCard && isTablet && index === 3 && (
                <ClaimProfileCard trackingData={claimProfileTrackingData} />
              )}
            </Fragment>
          );
        }
        return <PreviewCard key={data?.id} entityType={entityType} entity={data} trackingData={trackingData} />;
      })}
      {showClaimProfileCard && (
        <div className={classes.claimProfileCard}>
          <ClaimProfileCard trackingData={claimProfileTrackingData} />
        </div>
      )}
    </>
  );
};

const EntitySuggestions = ({
  entityType,
  inline = false,
  isGrid = false,
  styles,
  trackingData,
  claimProfileTrackingData,
  className,
}) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { entityId, permissions } = usePageContext();
  const { isMobile } = useDeviceTypeLayouts();
  const isLoggedIn = permissions?.isLoggedIn;

  const query = useMemo(() => {
    const queryFilters = {
      ...(entityType && { entityType }),
      ...(entityId && { entityId }),
      ...(!inline && { filters: { excludeIds: [entityId] } }),
      limit: getEntityRecommendationApiLimit({ entityType, inline, isLoggedIn }),
      queryConfig: {
        enabled: !!entityId,
      },
    };

    if (inline || entityType !== ENTITY_TYPES.PROFILE) {
      return queries.getEntityRecommendations(queryFilters);
    }

    return queries.getProfilesViewed(queryFilters);
  }, [entityType, entityId, inline, isLoggedIn]);

  const { data: response, isLoading } = useQuery(query);

  const showClaimProfileCard = inline && !isLoggedIn;

  if (isLoading) {
    return <Skeleton variant="rectangular" height={inline ? 44 : 260} className={classes.skeleton} />;
  }

  return (
    <div className={classnames({ [classes.inline_wrapper]: inline, [className]: !!className })}>
      <SectionBlock
        {...(!inline && { title: t(`${TP}.FN_POPULAR_TODAY`) })}
        className={classnames({
          [classes.sectionBlock]: !!inline,
          [styles?.sectionBlock]: !!styles?.sectionBlock,
        })}
        styles={{
          leftBoxIcon: isGrid ? classes.leftBoxIcon : null,
        }}
        withTitle={!inline}
        hideSectionDivider
      >
        {isGrid ? (
          <div className={classes.inline_grid}>
            <RenderOption
              response={response}
              inline={inline}
              isGrid={isGrid}
              entityType={entityType}
              trackingData={trackingData}
              showClaimProfileCard={showClaimProfileCard}
              claimProfileTrackingData={claimProfileTrackingData}
            />
          </div>
        ) : (
          <HorizontalScrollIndicators
            className={classnames(classes.horizontalScrollItems, {
              [styles?.horizontalScroll]: !!styles?.horizontalScroll,
            })}
            triggerSize={0}
            isArrowsEnabled={!isMobile}
          >
            <RenderOption
              response={response}
              inline={inline}
              entityType={entityType}
              trackingData={trackingData}
              showClaimProfileCard={showClaimProfileCard}
              claimProfileTrackingData={claimProfileTrackingData}
            />
          </HorizontalScrollIndicators>
        )}
      </SectionBlock>
    </div>
  );
};

EntitySuggestions.propTypes = {
  entityType: PropTypes.string,
};

export default EntitySuggestions;
